<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="Novo Tipo"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="openNew"
              />
              <Button
                label="Apagar"
                icon="pi pi-trash"
                class="p-button-danger"
                @click="confirmDeleteSelected"
                :disabled="!selectedProducts || !selectedProducts.length"
              />
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload
              mode="basic"
              accept="image/*"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            />-->
            <Button
              label="Exportar"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="tipos"
          v-model:selection="selectedProducts"
          dataKey="id"
          :paginator="true"
          :rows="50"
          :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
          responsiveLayout="scroll"
        >
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0">Gerenciar Tipos de Fatura</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Buscar"
                />
              </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column
            field="id"
            header="ID"
            :sortable="true"
            headerStyle="width:14%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Id</span>
              {{ slotProps.data.id }}
            </template>
          </Column>
          <Column
            field="tipo"
            header="Tipo"
            :sortable="true"
            headerStyle="min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">titulo</span>
              {{ slotProps.data.titulo }}
            </template>
          </Column>

          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning mt-2"
                @click="apagarTiposModal(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <Dialog
          v-model:visible="productDialog"
          :style="{ width: '450px' }"
          header="Gerenciar Tipo"
          :modal="true"
          class="p-fluid"
        >
          <div class="field">
            <label for="tipo">Título</label>
            <InputText
              id="tipo"
              v-model.trim="tipo.titulo"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !tipo.titulo }"
            />
            <small class="p-invalid" v-if="submitted && !tipo.titulo"
              >Título é obrigatório.</small
            >
          </div>

          <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-secondary p-button-sm"
              @click="hideDialog"
            />
            <Button
              label="Salvar"
              icon="pi pi-check"
              class="p-button-success p-button-sm"
              @click="salvarTipos"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          :modal="true"
          header="&nbsp;"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem; display: block"
            />
            <span style="display: block" v-if="empresa"
              >Apagar: <b>{{ empresa.razao_social }}</b
              >?</span
            >
          </div>
          <template #footer class="align-items-center">
            <Button
              label="Não"
              icon="pi pi-times"
              class="p-button-secondary p-button-sm"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Sim"
              icon="pi pi-check"
              class="p-button-danger p-button-sm"
              @click="apagarTipos"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header=""
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="tipos">Apagar tipos selecionadas?</span>
          </div>
          <template #footer>
            <Button
              label="Não"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Sim"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";

export default {
  data() {
    return {
      tipos: [],
      tipo: {},
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      selectedProducts: null,
      filters: {},
      submitted: false,
    };
  },
  created() {
    this.initFilters();
  },
  mounted() {
    this.listarTipos();
  },
  methods: {
    listarTipos() {
      this.axios
        .post("/tipos-faturas/listar")
        .then((response) => {
          console.log(response);
          this.tipos = response.data.dados;
        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    trocarStatus() {
      this.empresa.status = this.status.value;
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        });
      return;
    },
    openNew() {
      this.tipo = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    salvarTipos() {

      this.submitted = true;
      if (this.tipo.id) {
        this.axios
          .post("/tipos-faturas/editar", this.tipo)
          .then(() => {
            this.listarTipos();
            this.productDialog = false;
            this.tipo = {};
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      } else {
        this.axios
          .post("/tipos-faturas/adicionar", this.tipo)
          .then(() => {
            this.listarTipos();
            this.productDialog = false;
            this.tipo = {};
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },
    editProduct(tipo) {
      this.productDialog = true;
      this.tipo = { ...tipo};


    },
    apagarTiposModal(empresa) {
      this.empresa = empresa;
      this.deleteProductDialog = true;
    },
    apagarTipos() {
      this.axios
        .post("/tipos-faturas/apagar", this.empresa)
        .then(() => {
          this.listarTipos();
          this.deleteProductDialog = false;
          this.empresa = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Tipos apagada com sucsso!",
            life: 3000,
          });
        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      let obj = this.selectedProducts;
      Object.keys(obj).forEach((key) => {
        this.axios
          .post("/tipos-faturas/apagar", obj[key])
          .then(() => {
            this.listarTipos();
            this.deleteProductDialog = false;
            this.tipo = {};
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Tipos apagada com sucesso!",
              life: 3000,
            });
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Erro, chama o t.i",
              life: 3000,
            });
          });
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
